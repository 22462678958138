import React from 'react';
import ContributorsGraph from './ContributorsGraph';
import CropsGraph from './CropsGraph';
import PDGraph from './PDGraph';
import './Statistics.css';

const Statistics = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <div className='card-container'>
                <div className='card-wrapper'>
                    <ContributorsGraph />
                </div>
                <div className='card-wrapper'>
                    <CropsGraph />
                </div>
                <div className='card-wrapper'>
                    <PDGraph />
                </div>
            </div>
        </div>
    );
}

export default Statistics;
