import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeatMapContainer from './components/map/HeatMapContainer';
import ImageDivisionListComponent from './components/image-library/ImageDivisionListComponent';
import AssociationsPage from './components/associations/AssociationsPage';
import AssociationInfoPage from './components/associations/AssociationInfoPage'
import CropImageListComponent from './components/image-library/CropImageListComponent';
import CropdexNavbar from './components/navigation/CropdexNavbar';
import LoginComponent from './components/authorization/Login';
import EditCropImagePage from './components/annotation/EditCropImagePage';
import Statistics from './components/statistics/Statistics';
import LabelPage from './components/label/LabelPage';

function App() {
	return (
		<div style={{ backgroundColor: '#f8f9fa' }}>
			<Router>
				<CropdexNavbar />
				<Routes>
					<Route path="/" 
						element={<HeatMapContainer/>}
					/>
					<Route path="/login" 
						element={<LoginComponent/>} 
					/>
					<Route path="/associations"
						element={<AssociationsPage/>}
					/>
					<Route path="/crops"
						element={<ImageDivisionListComponent division="crop"/>}
					/>
					<Route path="/crops/:crop_id"
						element={<ImageDivisionListComponent division="cropvalidity"/>}
					/>
					<Route path="/crops/:crop_id/:photo_validity"
						element={<ImageDivisionListComponent division="cropvaliditylabeltype"/>}
					/>
					<Route path="/crop-images/:crop_id/:photo_validity/:label_type"
						element={<CropImageListComponent/>}
					/>
					<Route path="/crop-images/:crop_id/:label_type/:photo_validity/:imageid"
						element={<EditCropImagePage/>}
					/>
					<Route path="/association-info/:associd"
						element={<AssociationInfoPage/>}
					/>
					<Route path="/statistics"
						element={<Statistics/>}
					/>
					<Route path="/labels"
						element={<LabelPage/>}
					/>
					{/*<Route path="/contact" component={Contact} />*/}
				</Routes>
			</Router>
		</div>
	);
}

export default App;