import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Spinner } from 'react-bootstrap';
import { Chart as ChartJS } from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import Cookies from 'js-cookie';

const PDGraph = () => {
    const [cdex_data, setData] = useState(null);
    const [unique_crops, setUniqueCrops] = useState([]);
    const [selected_crop, setSelectedCrop] = useState("");
    const [pests_diseases, setPestsDiseases] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try{
                const cookie = Cookies.get('cdexuser');

                const data_query = 'https://www.cropdex.org/crops/crop-pest-disease'
                
                const response = await fetch(data_query, {method: 'GET', headers: {'Authorization': `Bearer ${cookie}`, 'Content-Type':'application/json'}});
                
                const jsonData = await response.json();
                setData(jsonData);

                const unique_crops = [
                    ...new Set(jsonData.map((item) => item.crop_name))
                ];
                setUniqueCrops(unique_crops);

                if (unique_crops.length > 0) {
                    setSelectedCrop(unique_crops[0]);
                }

            } catch (error){
                console.error("Error fetching data: ", error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (cdex_data && selected_crop) {
            const filteredData = cdex_data.filter((item) => item.crop_name === selected_crop);
            console.log("Filtered pestsDiseases: ", filteredData);
            setPestsDiseases(filteredData);
        }
    }, [selected_crop, cdex_data]);

    const handleSelect = (crop) => {
        console.log("Selected crop: ", crop);
        setSelectedCrop(crop);
    };

    if (cdex_data == null){
        return(
            <div>
                <Spinner animation="border" />
            </div>
        )
    } else {
        return (
            <Card style={{ width: '100%' }}>
                <Card.Body>
                    <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {selected_crop}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {unique_crops.map((item, index) => (
                                <Dropdown.Item key={index} eventKey={item}>
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    {selected_crop && pests_diseases != null && (
                        <div key={selected_crop}>
                            <Doughnut
                                data = {{
                                    labels: pests_diseases.map((item) => item.pest_disease),
                                    datasets: [
                                        {
                                            label: "Occurences",
                                            data: pests_diseases.map((item) => item.total_count),
                                        }
                                    ]
                                }}
                                options = {{
                                    plugins: {
                                        legend: {
                                            position: 'left',
                                            labels: {
                                                font: {size: 14},
                                                padding: 10,
                                                generateLabels: (chart) => {
                                                    const datasets = chart.data.datasets;
                                                    return datasets[0].data.map((data, i) => ({
                                                        text: `${chart.data.labels[i]}: ${data}`,
                                                        fillStyle: datasets[0].backgroundColor[i],
                                                        fontColor: '#666',
                                                        index: i
                                                    }))
                                                }
                                            }
                                        },
                                        title: {
                                            display: true,
                                            text: `Number of Data Gathered per Pest/Disease for ${selected_crop}`,
                                            font: {size: 24}
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}

                </Card.Body>
            </Card>
        );
    }
}

export default PDGraph;