import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Card,  Spinner } from 'react-bootstrap';
import { Chart as ChartJS } from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const ContributorsGraph = () => {
    const [cdex_data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try{
                const cookie = Cookies.get('cdexuser');

                const data_query = 'https://www.cropdex.org/associations/all-associations';
                
                const response = await fetch(data_query, {method: 'GET', headers: {'Authorization':`Bearer ${cookie}`, 'Content-Type':'application/json'}});

                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, []);

    if (cdex_data == null){
        return(
            <div>
                <Spinner animation="border" />
            </div>
        );
    } else {
        return(
            <Card style={{ width: '100%', height: '40rem' }}>
                <Card.Body>
                    <Doughnut
                        data = {{
                            labels: cdex_data.map((item) => item.division),
                            datasets: [
                                {
                                    label: "Images",
                                    data: cdex_data.map((item) => item.total_images),

                                }
                            ]
                        }}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: 'left',
                                    labels: {
                                      font: {size: 14}
                                    }
                                  },
                                  title: {
                                      display: true,
                                      text: 'Number of Images per Contributor',
                                      font: {size: 24}
                                  }
                            }
                        }}
                    />
                </Card.Body>
            </Card>
        );
    }
}

export default ContributorsGraph;